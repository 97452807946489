<template>
  <div class="van-main license-detail">
    <div class="area-title">
      <p class="title">
        批文信息
      </p>
    </div>
    <div class="van-common-detail">
      <van-cell-group>
        <van-cell title="许可证编号" :value="utils.isEffectiveCommon(licenseForm.licenseNumber)" />
        <van-cell title="申请单位名称" :value="utils.statusFormat(licenseForm.companyName, 'PlatformEnterprise')" />
        <van-cell title="有限期限" :value="utils.dateFormat(licenseForm.limitedPeriodStart) + '-' + utils.dateFormat(licenseForm.limitedPeriodEnd)" />
        <van-cell title="检疫物名称" :value="utils.isEffectiveCommon(licenseForm.quarantineObjectsName)" />
        <van-cell title="品种" :value="utils.isEffectiveCommon(licenseForm.varieties)" />
        <van-cell title="HS编码" :value="utils.isEffectiveCommon(licenseForm.hsCode)" />
        <van-cell title="数/重量（KG）" :value="utils.moneyFormat(licenseForm.weight)" />
        <!-- <van-cell title="国家" :value="utils.isEffectiveCommon(licenseForm.country)" /> -->
        <van-cell title="原产地" :value="utils.isEffectiveCommon(licenseForm.placeOrigin)" />
        <van-cell title="目的港" :value="utils.isEffectiveCommon(licenseForm.destinationPort)" />
        <van-cell title="运输路线及方式" :value="utils.isEffectiveCommon(licenseForm.tansportationRoute)" />
        <van-cell title="境外生产加工、存放单位" :value="utils.isEffectiveCommon(licenseForm.storageCompany)" />
      </van-cell-group>
    </div>
    <div class="area-title">
      <p class="title">
        批文原件
      </p>
    </div>
    <div class="van-common-detail">
      <van-cell-group>
        <file-list :file-list="fileList" title="" />
      </van-cell-group>
    </div>
    <div class="area-title">
      <p class="title">
        批文使用情况
      </p>
    </div>
    <div class="van-common-detail use-detail">
      <van-cell-group>
        <van-cell title="已使用数量总计（KG）" :value="utils.moneyFormat(licenseForm.usedWeight)" />
        <van-cell title="剩余数量总计（KG）" :value="utils.moneyFormat(licenseForm.remainingUsableWeight)" />
      </van-cell-group>
    </div>
    <back-button />
  </div>
</template>

<script>
import { Cell, CellGroup, Divider, Button, Tab, Tabs, Toast, uploader } from 'vant'
import BackButton from '@/components/back-button'
import fileList from '@/components/file-list'
export default {
  components: {
    BackButton,
    fileList,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast,
    [uploader.name]: uploader
  },
  data () {
    return {
      id: this.$route.query.id,
      licenseForm: {
        limit: ''
      },
      fileList: []
    }
  },
  computed: {
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.api.license.detail(this.$route.query.id).then(result => {
        this.licenseForm = result.data.value
        this.licenseForm.limit = this.licenseForm.limitedPeriodStart.split('T')[0] - this.licenseForm.limitedPeriodEnd.split('T')[0]
        const fileList = []
        if (this.licenseForm.fileDetailInfoVo) {
          fileList.push(this.licenseForm.fileDetailInfoVo)
        }
        this.fileList = fileList
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less" scoped>
.license-detail {
  margin-bottom: 60px;
}
</style>
