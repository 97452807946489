var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main license-detail" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-cell-group",
            [
              _c("van-cell", {
                attrs: {
                  title: "许可证编号",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.licenseForm.licenseNumber
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "申请单位名称",
                  value: _vm.utils.statusFormat(
                    _vm.licenseForm.companyName,
                    "PlatformEnterprise"
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "有限期限",
                  value:
                    _vm.utils.dateFormat(_vm.licenseForm.limitedPeriodStart) +
                    "-" +
                    _vm.utils.dateFormat(_vm.licenseForm.limitedPeriodEnd)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "检疫物名称",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.licenseForm.quarantineObjectsName
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "品种",
                  value: _vm.utils.isEffectiveCommon(_vm.licenseForm.varieties)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "HS编码",
                  value: _vm.utils.isEffectiveCommon(_vm.licenseForm.hsCode)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "数/重量（KG）",
                  value: _vm.utils.moneyFormat(_vm.licenseForm.weight)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "原产地",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.licenseForm.placeOrigin
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "目的港",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.licenseForm.destinationPort
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "运输路线及方式",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.licenseForm.tansportationRoute
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "境外生产加工、存放单位",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.licenseForm.storageCompany
                  )
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-cell-group",
            [
              _c("file-list", {
                attrs: { "file-list": _vm.fileList, title: "" }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._m(2),
      _c(
        "div",
        { staticClass: "van-common-detail use-detail" },
        [
          _c(
            "van-cell-group",
            [
              _c("van-cell", {
                attrs: {
                  title: "已使用数量总计（KG）",
                  value: _vm.utils.moneyFormat(_vm.licenseForm.usedWeight)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "剩余数量总计（KG）",
                  value: _vm.utils.moneyFormat(
                    _vm.licenseForm.remainingUsableWeight
                  )
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 批文信息 ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 批文原件 ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 批文使用情况 ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }